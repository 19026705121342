import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Label, Margin } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useEffect } from 'react';

const SettingsWidget = () => {
  
    const [cameraScan, setCameraScan] = useState(()=> {
        // getting stored value
        const saved = localStorage.getItem("camera_scan");
        const initialValue = saved;
        return initialValue || "";
    });

    
    const [inventory, setInventory] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("inventory_flag");
        const initialValue = saved;
        return initialValue || "";
    });


    function handleSelectChange() {
        console.log("User selected new account")
    }


    const handleChange = () => {

        if (inventory === "") {
            let _val = "true"
            localStorage.setItem('inventory_flag', _val);
            setInventory(_val);
        } else {
            let _val = inventory == "true" ? "": "true"
            localStorage.setItem('inventory_flag', _val);
            setInventory(_val);
        }

    };

    const handleCameraScanChange = () => {

        if (cameraScan === "") {
            let _val = "true"
            localStorage.setItem('camera_scan', _val);
            setCameraScan(_val);
        } else {
            let _val = cameraScan == "true" ? "": "true"
            localStorage.setItem('camera_scan', _val);
            setCameraScan(_val);
        }

    };
    return (
        <>
            <h2>Settings </h2>
            <div style={{minHeight: '450px'}}>
                {/* <FormGroup>
                    <FormControlLabel control={<Checkbox onChange={()=>this.handleChange("inventory")} />} label="Inventory the items" />
                </FormGroup> */}
               <div style={{margin:'5px'}}> <input type='checkbox' defaultChecked={inventory} value={inventory} onChange={() => handleChange()} /> Send items to N3PR App in inventory queue </div>
               
                <div style={{margin:'5px'}} ><input type='checkbox' defaultChecked={cameraScan} value={cameraScan} onChange={() => handleCameraScanChange()} /> Enable the camera scanner </div>

                {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label"> Account</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={age}
                        label="Account"
                        onChange={handleChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty Twenty Twenty Twenty Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl> */}
            </div>
        </>
    );


}

export default SettingsWidget