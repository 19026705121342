import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav class="pa3 pa4-ns">
      <a href="/" class="link dim black b f6 f3-ns dib mr3" title="Home">Home</a>
      <a href="#" class="link dim gray    f6 f5-ns dib mr3"  title="Home">Preferences</a>
      <a href="#" class="link dim gray    f6 f5-ns dib mr3"  title="About">Logout</a>
    </nav>

      <Outlet />
    </>
  )
};

export default Layout;