import logo from './logo.png';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import Layout from './pages/layout';
import NoPage from './pages/nopage';
import Login from './pages/login';



function setToken(userToken) {
  localStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
  const tokenString = localStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.access_token
}


function App() {
  const token = getToken();
  console.log("Token ", token)

  if(!token) {
    return  <BrowserRouter><Login setToken={setToken} /></BrowserRouter>
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
