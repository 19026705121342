import React, { useState } from 'react';
import { Html5QrcodeScanner, Html5QrcodeSupportedFormats, Html5QrcodeScanType, Html5QrcodeScannerState } from "html5-qrcode";
import { useEffect, useRef } from 'react';
import { parseBarcode } from 'gs1-barcode-parser-mod';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { AppBar, Toolbar, IconButton, Typography, Stack, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import api from './../api/init'; // Import the wrapper
import { Snackbar } from "@mui/material"
import { useNavigate } from "react-router-dom";
import SettingsWidget from '../components/Settings/SettingsWidget';
import { CircularProgress } from "@mui/material";
import Modal from '@mui/material/Modal';
import FooterWidget from '../components/Footer/FooterWidget';



async function addItem(itemDetail) {
    return api.get('/api/returns/pull/status', itemDetail)
        .then((response) => {
            return response;
        })
        .catch(error => {
            if (error.response.status === 401) {
                // handle bad request error... 
                localStorage.removeItem('token');
                window.location.reload();
            }
            else if (error.response.status === 403) {
                // handle not found error... 
                return error.response;
            }
        });

}

async function getPullStatus(itemDetail) {
    return api.post('/api/returns/pull/status', itemDetail)
        .then((response) => {
            return response;
        })
        .catch(error => {
            if (error.response.status === 401) {
                // handle bad request error... 
                localStorage.removeItem('token');
                window.location.reload();
            }
            else if (error.response.status === 403) {
                // handle not found error... 
                return error.response;
            }
        });

}

const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Home = () => {
    const ndcInput = useRef(null)
    let html5QrcodeScanner;
    const navigate = useNavigate();
    const AUDIO = new Audio();
    AUDIO.autoplay = true;

    const DATA_URL = "https://www.orangefreesounds.com/wp-content/uploads/2021/12/Barcode-scanner-beep-sound.mp3";

    const [ndc, setNDC] = useState();
    const [lot, setLot] = useState();
    const [expiry, setExpiry] = useState();
    const [qty, setQty] = useState("1");
    const [pf, setPF] = useState("F");
    const [location, setLocation] = useState();
    const [pullStatus, setPullStatus] = useState(null)
    const [settings, setSettings] = useState(null)
    const [home, setHome] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState(null);
    const [spmsEnabledDialog, setSpmsEnabledDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const beep = () => {
        AUDIO.src = DATA_URL;
    }


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setPullStatus(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        localStorage.removeItem('token');
        window.location.reload();
    };

    const handleHome = () => {
        setSettings(null);
        setHome(true);
        window.location.reload();

    }
    const handleSettings = () => {
        setSettings(true);
        setAnchorEl(null);
        setHome(null);
    }

    async function getProfileInfo() {
        return api.get('/api/profile/me', {})
            .then((response) => {
                if (response) {
                    const _profile_obj = response.data
                    console.log(_profile_obj.spms_enabled)
                    if (_profile_obj.spms_enabled == true) {
                        setSpmsEnabledDialog(false)
                    } else {
                        setSpmsEnabledDialog(true)
                    }
                    console.log(_profile_obj);
                    setProfile(_profile_obj)
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    // handle bad request error... 
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            });

    }

    useEffect(() => {
        getProfileInfo();
        if (localStorage.getItem('camera_scan') == true || localStorage.getItem('camera_scan') == "true") {
            if (!html5QrcodeScanner?.getState()) {

                html5QrcodeScanner = new Html5QrcodeScanner(
                    "reader",
                    {
                        fps: 10,
                        qrbox: { width: 250, height: 250 },
                        // qrbox: { width: 300, height: 130 },
                        aspectRatio: 16 / 9,
                        showTorchButtonIfSupported: true,
                        //rememberLastUsedCamera: true,
                        useBarCodeDetectorIfSupported: true,
                        //    formatsToSupport: [Html5QrcodeSupportedFormats.DATA_MATRIX, Html5QrcodeSupportedFormats.QR_CODE],
                        focusMode: "continuous",
                        advanced: [{ zoom: 2.0 }],
                        experimentalFeatures: {
                            useBarCodeDetectorIfSupported: true
                        },
                        supportedScanTypes: [
                            Html5QrcodeScanType.SCAN_TYPE_CAMERA,
                            Html5QrcodeScanType.SCAN_TYPE_FILE,
                        ]
                    },
                )

            }




            html5QrcodeScanner.render(onScanSuccess);


            // cleanup function when component will unmount
            return () => {
                html5QrcodeScanner.clear().catch(error => {
                    console.error("Failed to clear html5QrcodeScanner. ", error);
                });
            };
        }
    }, []);

    const handleClear = async e => {
        setNDC("")
        setLot("")
        setExpiry("")
        setQty("1")
        setPF("F")
        ndcInput.current.focus()
        //if (html5QrcodeScanner.getState() ===  Html5QrcodeScannerState.PAUSED) {
        //  await html5QrcodeScanner.resume();
        //}
        // html5QrcodeScanner.clear();
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        if (localStorage.getItem('inventory_flag') == true || localStorage.getItem('inventory_flag') == "true") {
            console.log("Ready for the inventory")
            const status = await getPullStatus({
                "itemNumber": ndc,
                "qty": "" + qty,
                "lot": lot,
                "contents": pf,
                "expirationDate": expiry,
                "location": location,
                "inventory": "true"
            }
            );
            console.log(status)
            setIsLoading(false);
            //setPullStatus(status.data.status + " - " + status.data.message )
            setPullStatus(status)

        } else {
            const status = await getPullStatus({
                "itemNumber": ndc,
                "qty": qty,
                "lot": lot,
                "contents": pf,
                "expirationDate": expiry,
                "location": location
            });
            console.log(status)
            setIsLoading(false);
            //setPullStatus(status.data.status + " - " + status.data.message)
            setPullStatus(status)
        }
    }

    function parseScannedBarcode(scanned_data) {
        try {
            let answer = parseBarcode(scanned_data);
            console.log(`Parsed Barcode`, answer);
            setLot(answer)
            if (answer != undefined || answer != null || answer != "") {
                var parsedItems = answer.parsedCodeItems
                parsedItems.forEach(element => {
                    if (element.ai == '01') {
                        console.log("GTIN: " + element.data);
                        if (element.data.length == 14) {
                            var gtin = element.data
                            var gtin_ndc = gtin.slice(0, -1);
                            var ndc = gtin_ndc.substring(3)
                            console.log("NDC: " + ndc);
                            setNDC(ndc)
                        }
                    }
                    if (element.ai == '17') {
                        console.log("Expiry: " + element.data);
                        let dt_str = element.data;
                        let objectDate = new Date(dt_str);
                        let day = objectDate.getDate();
                        let month = objectDate.getMonth() + 1;
                        let year = objectDate.getFullYear();
                        let format1 = month + "/" + day + "/" + year;
                        console.log(format1); // 7/23/2022
                        setExpiry(format1)
                    }
                    if (element.ai == '10') {
                        console.log("Lot: " + element.data);
                        setLot("" + element.data)
                    }
                });
            }
        } catch (err) {
            console.log(err)
        }
    }

    function onScanSuccess(decodedText, decodedResult) {
        handleClear();
        // html5QrcodeScanner.clear();
        //handle the scanned code as you like, for example:
        if (html5QrcodeScanner.getState() !== Html5QrcodeScannerState.NOT_STARTED) {
            // Add this check to ensure success callback is not being called
            // from file based scanner.
            // Pause on scan result
            html5QrcodeScanner.pause();
        }
        beep();

        console.log(`Code matched = ${decodedText}`, decodedResult);
        var scanned_data = `${decodedText}`
        setNDC(scanned_data)
        if (scanned_data.startsWith("\u001d")) {
            scanned_data = scanned_data.replace("\u001d", "");
            console.log(`Removed the first unicode =${scanned_data}`);
        }
        //01003516721318212110000260036610AC9410217241231

        scanned_data = scanned_data.replace("\u001d", "")
        console.log(`Removed the 2nd unicode =${scanned_data}`);
        parseScannedBarcode(scanned_data)
        html5QrcodeScanner.resume();
        // if (html5QrcodeScanner.getState() === Html5QrcodeScannerState.SCANNING) {
        //     html5QrcodeScanner.pause(/* shouldPauseVideo= */ true);
        // }
    }

    function onScanFailure(error) {
        // handle scan failure, usually better to ignore and keep scanning.
        // for example:
        console.warn(`Code scan error = ${error}`);
    }

    function checkEnterKey(e) {
        if (e.keyCode == 13 || e.keyCode == 9) {
            console.log('value', e.target.value);
            processNDC(e.target.value)
        }
    }

    function processNDC(val) {
        if (localStorage.getItem('camera_scan') == true || localStorage.getItem('camera_scan') == "true") {
            console.log(val)
            //setNDC(val)
        }
        else {
            console.log(" Barcode scanner" + val)
            parseScannedBarcode(val)
        }
    }


    return (
        <Container maxWidth="sm">

            <div className='App'>
                <AppBar>
                    <Toolbar>
                        <IconButton color="inherit" aria-label="menu" onClick={handleMenu} >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant='h6'>
                            Pull the Shelves {'- ' + profile?.name}
                        </Typography>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleHome}>Home</MenuItem>
                            <MenuItem onClick={handleSettings}>Settings</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>

                {home &&
                    <form onSubmit={handleSubmit}>
                        <label>
                            {profile && <h3> {profile.current_site} </h3>}
                            <p>Scan the item </p>
                            <div id="reader" width="600px"></div>
                        </label>
                        <TextField inputRef={ndcInput} fullWidth id="txtNDC" InputLabelProps={{ shrink: true }} label="NDC" value={ndc} onKeyDown={checkEnterKey} onChange={e => setNDC(e.target.value)} variant="filled" />
                        <TextField fullWidth id="txtLot" InputLabelProps={{ shrink: true }} label="Lot#" value={lot} onChange={e => setLot(e.target.value)} variant="filled" />
                        <TextField fullWidth id="txtExpiry" InputLabelProps={{ shrink: true }} label="Expiry Date" value={expiry} onChange={e => setExpiry(e.target.value)} variant="filled" />
                        <TextField fullWidth id="txtQty" InputLabelProps={{ shrink: true }} label="Quantity" value={qty} onChange={e => setQty(e.target.value)} variant="filled" />
                        <TextField fullWidth id="txtPF" InputLabelProps={{ shrink: true }} label="P/F" value={pf} onChange={e => setPF(e.target.value)} variant="filled" />
                        <TextField fullWidth id="txtLocation" InputLabelProps={{ shrink: true }} label="Location" value={location} onChange={e => setLocation(e.target.value)} variant="filled" />

                        <Button onClick={handleSubmit} style={{ margin: '20px' }} variant="contained">Submit</Button>
                        <Button onClick={handleClear} style={{ margin: '20px' }} variant="contained">Clear</Button>

                        <Stack alignItems="center" spacing={5}>
                            {isLoading && (<CircularProgress color="secondary" />)}
                        </Stack>

                        {pullStatus && <Snackbar open={pullStatus} autoHideDuration={5000} onClose={handleClose} message={pullStatus.data.status + " - " + pullStatus.data.message} action={pullStatus.data.send_to} />}


                    </form>
                }
            </div>
            {settings && <SettingsWidget open={settings} />}

            {spmsEnabledDialog && <Modal
                open={setSpmsEnabledDialog}
                onClose={handleLogout}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth="80%"

            >
                <Box sx={modal_style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Warning
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        This application is not enabled for this site, Please contact <a href='mailto:support@n3pr.com'>support@n3pr.com</a> to enable it.
                    </Typography>
                </Box>
            </Modal>}

            <FooterWidget />

        </Container>
    );

};

export default Home;