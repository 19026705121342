import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './login.css';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import logo from './../images/rxpull192.png'
import FooterWidget from '../components/Footer/FooterWidget';


const url = process.env.REACT_APP_API_ENDPOINT;

async function loginUser(credentials) {
  return fetch(url +'/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    //.then(data => data.json())
    .then(function (response) {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
}

export default function Login({ setToken }) {
  const navigate = useNavigate();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    if (token == null) {
      setErrorMessage('Invalid username or password!');
      return;
    }
    console.log(token)
    setToken(token);
    navigate("/")
    window.location.reload();

  }

  return (
    <Container maxWidth="sm">
      <div className='App'>
      <img src={logo} alt="logo" />
        <h1>Please Log In</h1>
        <div>
          <TextField id="txtUserName" label="Username" variant="filled" onChange={e => setUserName(e.target.value)} />
        </div> <div>
          <TextField id="txtPassword" label="Password" type='password' variant="filled" onChange={e => setPassword(e.target.value)} />
        </div>
        <div>
          <Button onClick={handleSubmit} style={{ margin: '20px' }} variant="contained">Submit</Button>
        </div>
        {errorMessage && (
          <p className="error"> {errorMessage} </p>
        )}
      </div>
      <FooterWidget privacy={true} />
    </Container>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
