// Wrapper for HTTP requests with Axios
import axios from 'axios';

const url = process.env.REACT_APP_API_ENDPOINT;

const api = axios.create({
   baseURL: url,
  // baseURL: 'http://127.0.0.1:8080',
});

// Add an interceptor for all requests
api.interceptors.request.use(config => {
   // Retrieve the access token from React state or a state management system
   //const accessToken = "${localStorage.getItem('token')}";
   const token =JSON.parse(localStorage.getItem('token'))
   console.log(token.access_token)
   // Add the access token to the Authorization header
   config.headers.Authorization = `Bearer ${token.access_token}`;
   //axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
 
   return config;
});

export default api;
