import React from "react";

const FooterWidget = ({privacy}) => (
    <footer>
        
        <span>&copy; 2024 N3PR Inc.</span>
        {privacy ? <p><a target="_blank" href="https://www.n3pr.com/privay-policy">Privacy Policy</a></p> 
                 : <p><a href="https://app.n3pr.com">Use N3PR app to manage Inventory and Pulled items</a></p>}
    </footer>

);

export default FooterWidget;